import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default function Donatebreadcreumb() {
  return (
    <div className="breadcumb-sub-page about-banner subpage-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 pad40">
            <div className="inner-title text-center">
              <h3 className="heading-wrapper about-breadcub-title text-center"> Donate for a Heartful Humanity </h3>
              <div classname="center-item">
              <Breadcrumb>
              <Breadcrumb.Item href="/#home"><span  className="home-color-offerings">Home</span></Breadcrumb.Item>
              <Breadcrumb.Item active> <span  className="home-color-offerings"> Donate for a Heartful Humanity</span></Breadcrumb.Item>
            </Breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
