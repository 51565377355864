import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Donatebreadcrumb from "../components/donate/donatebreadcrumb";

import Donateconetentpage from "../components/donate/donatecontent";

// markup
const DonatePage = () => {
  return (
    <div>
      <Seo title="FAQ" />

      <Layout isSticky>
        <section>
          <Donatebreadcrumb />
        </section>
        <section>
          <Donateconetentpage />
        </section>
      </Layout>
    </div>
  );
};

export default DonatePage;
