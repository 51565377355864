import React from "react";

import Table from 'react-bootstrap/Table';




export default function donatecontentpage() {
    return (
        <section className="outer-sucess-wrapper pad60">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <div class="entry-content">


                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p className="p-tag-donate">
                                            Share in the work of bringing peace, compassion, happiness and harmony to people of all walks of life by providing financial support to Heartfulness programs conducted worldwide by Shri Ram Chandra Mission and Sahaj Marg Spirituality Foundation.Donation of any size make it possible for us to change lives by teaching the Heartfulness techniques. These programs are conducted exclusively by volunteers, and the techniques are taught free of charge to participants. Our dedicated volunteers, many of whom are professionals, donate their time and talent and cover their own expenses. Your donation will support our programs in schools, universities and colleges, corporates, hospitals and villages in 130 countries, as well as to any interested groups and communities.
                                        </p>
                                        <p className="p-tag-donate mt-3">
                                            <b>Donations can be made to the SRCM RSA account as follows:</b><br /><br />

                                            <Table className="tablelist ">
                                          
                                          <tbody>
                                              <tr>
                                                
                                                  <td width="160">Account Name</td>
                                                  <td>: <b className="mx-2">  Shri Ram Chandra Mission,</b></td>
                                                 
                                              </tr>
                                              <tr>
                                                
                                                  <td width="160">Bank </td>
                                                  <td> : <b className="mx-2">  Standard Bank,</b></td>
                                                
                                              </tr>
                                              <tr>
                                                
                                                <td width="160">Branch </td>
                                                <td>:<b className="mx-2" >  Fordsburg Branch,</b></td>
                                                
                                            </tr>
                                            <tr>
                                                
                                                <td width="160">Account Number</td>
                                                <td >: <b className="mx-2"> 401273253</b></td>
                                               
                                            </tr>
                                          </tbody>
                                      </Table>
                                        </p>
                                        
                                        <hr />

                                        <p className="p-tag-donate">
                                            For International Donations use Swift Code: <b>SBZAZAJJ</b>
                                        </p>
                                        <hr />
                                        <p className="p-tag-donate">
                                            For further enquiries please contact: Secretary Shri Ram Chandra Mission on email:   
                                             <a className="" href="mailto: za.secretary@srcm.org "> za.secretary@srcm.org</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
